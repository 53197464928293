import "../styles/main.scss";

import "regenerator-runtime/runtime";
import Category from "./extends/category";
import { slideToggle } from "./extends/components/slideToggle";

import Swiper, { Scrollbar } from "swiper";
import { OverlayScrollbars } from "overlayscrollbars";

import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

const acf = import.meta.glob("../../template-parts/blocks/acf/**/index.js", { eager: true });
const reactFront = import.meta.glob("../../template-parts/blocks/react/**/front.js", { eager: true });

const lightboxVideo = GLightbox({
  selector: ".video-lightbox, .is-style-video a",
  autoplayVideos: true,
});

import NavScrollSpy from "nav-scroll-spy";
import Tabs from "../../template-parts/blocks/react/tabs/front";
import isInViewport from "./extends/components/is-in-viewport";

Tabs();

function getStyle(oElm, strCssRule) {
  let strValue = "";
  if (document.defaultView && document.defaultView.getComputedStyle) {
    strValue = document.defaultView.getComputedStyle(oElm, "").getPropertyValue(strCssRule);
  } else if (oElm.currentStyle) {
    strCssRule = strCssRule.replace(/\-(\w)/g, (strMatch, p1) => p1.toUpperCase());
    strValue = oElm.currentStyle[strCssRule];
  }
  return strValue;
}

document.addEventListener("DOMContentLoaded", () => {
  const footerCta = document.querySelector(".footer-cta");
  const siteFooter = document.querySelector(".site-footer");

  const checkScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition >= 300) {
      footerCta?.classList.add("active");
    } else {
      footerCta?.classList.remove("active");
    }

    // Check if siteFooter exists before calling isInViewport
    if (siteFooter && isInViewport(siteFooter)) {
      footerCta?.classList.remove("active");
    } else if (scrollPosition >= 300) {
      footerCta?.classList.add("active");
    }
  };

  window.addEventListener("scroll", checkScroll);
  checkScroll();

  const handleHashScroll = () => {
    const hash = window.location.hash;
    if (hash) {
      const buffer = 85;
      const scrollToEl = document.querySelector(hash);

      if (scrollToEl) {
        const topOfElement = scrollToEl.getBoundingClientRect().top + window.scrollY - buffer;

        const checkScrollCompletion = () => {
          const currentScroll = window.scrollY;
          if (Math.abs(currentScroll - topOfElement) < 5) {
            triggerFadeIn();
          } else {
            requestAnimationFrame(checkScrollCompletion);
          }
        };

        const triggerFadeIn = () => {
          const fadeElements = document.querySelectorAll(".fade");
          fadeElements.forEach((el) => {
            if (isInViewport(el)) {
              el.classList.add("show");
              if ("delay" in el.dataset) {
                const delay = el.dataset.delay * 200;
                el.style.transitionDelay = `${delay}ms`;
              }
            }
          });
        };

        window.scrollTo({ top: topOfElement, behavior: "smooth" });
        requestAnimationFrame(checkScrollCompletion);
      }
    }
  };

  window.addEventListener("hashchange", handleHashScroll);
  handleHashScroll();

  const searchButton = document.querySelector("button.search");
  const searchClose = document.querySelector("#closeSearch");
  const searchForm = document.querySelector(".site-search");

  if (searchButton) {
    searchButton.addEventListener("click", () => {
      searchForm.classList.toggle("active");
    });
  }

  if (searchClose) {
    searchClose.addEventListener("click", () => {
      searchForm.classList.toggle("active");
    });
  }

  const scrollNav = document.querySelector(".site-header__scrollnav");
  if (scrollNav) {
    window.scroll(0, 1);

    let scrollOffset = parseInt(getStyle(document.getElementById("app"), "padding-top"), 10);
    window.addEventListener("scroll", () => {
      scrollOffset = parseInt(getStyle(document.getElementById("app"), "padding-top"), 10);
    });

    const spy = new NavScrollSpy({
      offset: scrollOffset * 2 + 2,
    });
    spy.init();

    scrollNav.querySelectorAll('a[href^="#"]').forEach((elem) => {
      elem.addEventListener("click", (e) => {
        e.preventDefault();
        const buffer = -2;
        const scrollToEl = document.querySelector(elem.hash);
        const topOfElement = window.scrollY + scrollToEl.getBoundingClientRect().top - scrollOffset - buffer;
        window.scroll({ top: topOfElement, behavior: "smooth" });
      });
    });
  }

  const scrollNavBlock = document.querySelector(".scroll-nav");
  if (scrollNavBlock) {
    let scrollOffset = parseInt(getStyle(document.getElementById("app"), "padding-top"), 10);
    window.addEventListener("scroll", () => {
      scrollOffset = parseInt(getStyle(document.getElementById("app"), "padding-top"), 10);
    });

    scrollNavBlock.querySelectorAll('a[href^="#"]').forEach((elem) => {
      elem.addEventListener("click", (e) => {
        e.preventDefault();
        const buffer = 100;
        const scrollToEl = document.querySelector(elem.hash);
        const topOfElement = window.scrollY + scrollToEl.getBoundingClientRect().top - scrollOffset - buffer;
        window.scroll({ top: topOfElement, behavior: "smooth" });
      });
    });
  }

  let scrollYPos = window.scrollY;
  const html = document.getElementsByTagName("html");
  window.addEventListener("scroll", () => {
    scrollYPos = window.scrollY;
    if (scrollYPos > 1) {
      html[0].classList.add("scrolled");
    } else {
      html[0].classList.remove("scrolled");
    }
  });

  const goTop = document.getElementById("goTop");
  if (goTop) {
    goTop.addEventListener("click", (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
    });
  }

  Category();

  const menuElements = document.querySelectorAll(".esg-menu a");
  menuElements.forEach((element) => {
    if (element.textContent.includes("ESG")) {
      const index = element.textContent.indexOf("ESG");
      const before = element.textContent.slice(0, index);
      const after = element.textContent.slice(index + 3);
      element.innerHTML = `${before}<span>ESG</span>${after}`;
    }
  });

  document.querySelectorAll(".esg-menu").forEach((menu) => {
    const currentItems = menu.querySelectorAll(".current-menu-item");
    if (currentItems.length > 1) {
      currentItems.forEach((item, index) => {
        if (index > 0) {
          item.classList.remove("current-menu-item");
        }
      });
    }
  });

  document.querySelectorAll(".site-header__nav--primar").forEach((menu) => {
    const currentItems = menu.querySelectorAll(".current-menu-parent");
    if (currentItems.length > 1) {
      currentItems.forEach((item, index) => {
        if (index > 0) {
          item.classList.remove("current-menu-item");
        }
      });
    }
  });

  const learnMoreAuthor = document.querySelectorAll(".jsLearnMoreAuthor");
  learnMoreAuthor.forEach((elem) => {
    elem.addEventListener("click", (e) => {
      slideToggle(e.target.nextElementSibling, 500);
      e.target.style.visibility = "hidden";
    });
  });

  const hideLearnMoreAuthor = document.querySelectorAll(".jsHideLearnMoreAuthor");
  hideLearnMoreAuthor.forEach((elem) => {
    elem.addEventListener("click", (e) => {
      slideToggle(e.target.closest(".info-additional"), 500);
      e.target
        .closest(".info")
        .querySelectorAll(".jsLearnMoreAuthor")
        .forEach((learnMore) => {
          learnMore.style.visibility = "visible";
        });
    });
  });
});

const swiperRelatedCarousel = new Swiper(".single__related--list .swiper", {
  modules: [Scrollbar],
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
  slidesPerView: 1,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
  },
});

const shareBtn = document.getElementById("share");
if (shareBtn) {
  const shareFallback = document.getElementById("share-fallback");
  const shareLink = document.getElementById("share-btn");
  const pageTitle = document.title;
  const pageUrl = window.location.href;

  shareBtn.addEventListener("click", () => {
    if (window.matchMedia("(max-width: 1024px)").matches && navigator.share) {
      navigator.share({
        text: pageTitle,
        url: pageUrl,
      });
    } else {
      shareFallback.style.display = "flex";
      shareLink.style.display = "none";
    }
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const currentProtocol = window.location.protocol;
  const currentHost = window.location.host;

  document.querySelectorAll("a").forEach((aTag) => {
    const href = aTag.getAttribute("href");
    if (href && !href.startsWith("#") && !href.startsWith("mailto:") && !href.startsWith("tel:") && !aTag.hasAttribute("target")) {
      try {
        const linkUrl = new URL(href, window.location.href);
        const linkProtocol = linkUrl.protocol;
        const linkHost = linkUrl.host;

        if (linkProtocol === "http:" || linkProtocol === "https:") {
          if (linkHost !== currentHost && linkHost !== "complianceandrisks.com") {
            aTag.setAttribute("target", "_blank");
            aTag.setAttribute("rel", "noreferrer noopener");
          }
        } else if (href.startsWith("/")) {
          aTag.setAttribute("href", `${currentProtocol}//${currentHost}${href}`);
          aTag.setAttribute("target", "_blank");
          aTag.setAttribute("rel", "noreferrer noopener");
        }
      } catch (error) {
        console.error(`Error processing URL: ${href}. Error message: ${error.message}`);
      }
    }
  });

  // const blackNav = document.querySelector(".black-nav .scroll, .page-nav .scroll");
  // if (blackNav) {
  //   OverlayScrollbars(blackNav, {
  //     scrollbars: {
  //       visibility: "always",
  //     },
  //   });
  // }

  // const expertOverview = document.querySelector(".expert-overview .description");
  // if (expertOverview) {
  //   OverlayScrollbars(expertOverview, {
  //     scrollbars: {
  //       autoHide: "never",
  //     },
  //   });
  // }

  const expertExperience = document.querySelector(".expert-experience .scroll");
  if (expertExperience) {
    OverlayScrollbars(expertExperience, {
      scrollbars: {
        autoHide: "never",
      },
    });
  }

  const expertQualifications = document.querySelector(".expert-qualifications .scroll");
  if (expertQualifications) {
    OverlayScrollbars(expertQualifications, {
      scrollbars: {
        autoHide: "never",
      },
    });
  }
});
