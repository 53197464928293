import { slideUp, slideToggle } from "./components/slideToggle";

export default function Category() {
  const category = document.querySelector(".resources-category");
  if (category) {
    console.log("1");

    const form = document.querySelector(".resources-filters__filters form");
    const list = document.querySelector(".current-filters--list");
    const termLists = document.querySelectorAll(".list");
    const clear = document.querySelector("#clear");
    const checkboxes = document.querySelectorAll(".resources-filters__filters input[type='checkbox']");

    let filters = {};
    let selectedCheckboxes = [];

    form?.addEventListener("submit", () => {
      termLists.forEach((el) => {
        slideUp(el, 500);
      });
    });

    const fieldsets = document.querySelectorAll(".resources-filters__filters fieldset");
    fieldsets.forEach((fieldset) => {
      const legend = fieldset.querySelector("legend");
      if (legend) {
        legend.addEventListener("click", (event) => {
          event.preventDefault();
          fieldsets.forEach((otherFieldset) => {
            if (otherFieldset !== fieldset) {
              const list = otherFieldset.querySelector(".list");
              if (list) {
                slideUp(list, 500);
              }
            }
          });
          const termsList = fieldset.querySelector(".list");
          if (termsList) {
            slideToggle(termsList, 500);
          }
        });
      }
    });

    // Create a list of checked filters on page load
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        const filter = {};
        filter.id = checkbox.id;
        filter.name = checkbox.nextElementSibling.textContent.trim();
        filters[filter.id] = filter;
        selectedCheckboxes.push(filter);
        list.appendChild(createFilterSpan(filter.id, filter.name));
      }
    });

    // Create a span element with the given id and name for the filter list
    function createFilterSpan(id, name) {
      const span = document.createElement("span");
      span.dataset.id = id;
      span.textContent = name;
      return span;
    }

    // Remove filter from the list and uncheck the checkbox
    function removeFilter(id) {
      const filter = filters[id];
      if (filter) {
        const checkbox = document.getElementById(id);
        if (checkbox) {
          checkbox.checked = false;
        }
        delete filters[id];
        selectedCheckboxes = selectedCheckboxes.filter((selectedFilter) => selectedFilter.id !== id);
        const span = list.querySelector(`[data-id='${id}']`);
        if (span) {
          span.remove();
        }
        if (Object.keys(filters).length === 0) {
          clear.style.display = "none";
        }
        list.dispatchEvent(new CustomEvent("filterChanged"));
      }
    }

    // Listen for clicks on the clear button and remove all filters
    clear.addEventListener("click", () => {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      filters = {};
      selectedCheckboxes = [];
      list.innerHTML = "";
      clear.style.display = "none";
      list.dispatchEvent(new CustomEvent("filterChanged"));
      form.submit();
    });

    // Listen for clicks on the filter list and remove individual filters
    list.addEventListener("click", (event) => {
      if (event.target.tagName === "SPAN") {
        const id = event.target.dataset.id;
        removeFilter(id);
        list.dispatchEvent(new CustomEvent("filterChanged"));
        form.submit();
      }
    });

    // update the filter list
    function updateFilters() {
      const filterList = [...document.querySelectorAll(".resources-filters__current .current-filters--list span")];
      const list = document.querySelector(".resources-filters__current .current-filters--list");
      list.innerHTML = "";
      if (filterList.length > 0) {
        clear.style.display = "block";
      } else {
        clear.style.display = "none";
      }
      filterList.forEach((filter) => {
        list.appendChild(filter);
      });
    }

    updateFilters();
  }
}
